import React from "react"
import { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Panel from "../components/Panel"
import PanelContent from "../components/PanelContent"
import FluidImage from "../components/FluidImage"
import ReactMarkdown from "react-markdown"

export const ExhibitionTemplate = ({
  image,
  contain = false,
  cartel: { title, subtitle, description, description_secondary },
  panel: { title: panelTitle, text, text_secondary, videos, links }
}) => {
  const [toggle, setToggle] = useState(false)

  const handleClick = () => setToggle(!toggle)

  return (
    <>
      <div className="content__panel-clicker" onClick={handleClick}>
        <FluidImage image={image} alt="" contain={contain} />
      </div>
      <Panel toggle={toggle}>
        <PanelContent
          title={panelTitle}
          text={text}
          text_secondary={text_secondary}
          videos={videos}
          links={links}
        />
      </Panel>
      <div className="cartel">
        <div className="cartel__title">
          <h3>{title}</h3>
          <h4>{subtitle}</h4>
        </div>
        <div className="cartel__description">
          <ReactMarkdown
            source={description_secondary}
            renderers={{
              link: props => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              )
            }}
          />
          <ReactMarkdown
            source={description}
            renderers={{
              link: props => (
                <a href={props.href} target="_blank" rel="noopener noreferrer">
                  {props.children}
                </a>
              )
            }}
          />
        </div>
      </div>
    </>
  )
}

const Exhibition = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout className="exhibition">
      <ExhibitionTemplate
        image={frontmatter.image}
        contain={frontmatter.contain}
        cartel={frontmatter.cartel}
        panel={frontmatter.panel}
      />
    </Layout>
  )
}

export default Exhibition

export const pageQuery = graphql`
  query ExhibitionTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "exhibition" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contain
        cartel {
          title
          subtitle
          description
          description_secondary
        }
        panel {
          title
          text
          text_secondary
          videos {
            image {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            link
            title
            subtitle
            duration
          }
          links {
            label
            label_secondary
            link
          }
        }
      }
    }
  }
`
